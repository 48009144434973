
.modal-video {
    z-index: 20 !important;
    background-color: #FFFFFF00;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left:40%;
    transform: translate(-50%, -50%);
    border: none;
}

.modal-video-inner{
    width: 100%;
    height: 100%;
    border: none;
}

.modal-video-body {
    height: 100%;
    width: 100%;
}

.modal-video-movie-wrap{
    padding-bottom: 0 !important;
    border: none;
    margin: 0px;
}

.modal-video-movie-wrap:hover{
    border: none;
}

.modal-video-close-btn {
    width: 35px !important;
    height: 35px !important;
    border: none;
    position: absolute;
    top: -35px;
    right: -35px;
    color: #1a1a1a;
    background:url(../img/icons/cross-solid.png) no-repeat;
    background-size: contain;
    background-color: #2c3134;
    z-index: 3;
}

.modal-video-body iframe {
    width: 960px !important;
    height: 540px !important;
}

.navbar-admin {
    width: fit-content !important;
    left: 95px !important;
}

