.error-recover {
    color: darkred;
}

.wrapper-error-recover {
    padding: 1rem;
    text-align: center;
}

.button-recover {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    color: #d8636f;
    cursor: pointer;
}